/* eslint-disable no-nested-ternary */
import React, { useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { isMobileOnly } from 'react-device-detect';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { Spinner } from 'reactstrap';
import { useTransition, animated } from 'react-spring';
import { fetchCalendar } from '../../store/actions';

import Metadata from '../../components/Metadata';
import 'add-to-calendar-button';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'primereact/resources/themes/lara-light-cyan/theme.css';

import './index.scss';
import HorizontalIframeHomepage from '../../components/AdserverIframe/Homepage/HorizontalIframe';
import HalfBanner from '../../components/AdserverIframe/HalfBanner';

// German locale for react calendar
const calendarLocale = {
    firstDayOfWeek: 1,
    dayNames: [
        'Sonntag',
        'Montag',
        'Dienstag',
        'Mittwoch',
        'Donnerstag',
        'Freitag',
        'Samstag',
    ],
    dayNamesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    monthNames: [
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember',
    ],
    monthNamesShort: [
        'Jan',
        'Feb',
        'Mär',
        'Apr',
        'Mai',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Okt',
        'Nov',
        'Dez',
    ],
    today: 'Heute',
    clear: 'Löschen',
};

// Helper function to generate an array of dates within each date range
const generateHighlightedDates = (articles) => {
    const dates = [];
    articles.forEach((article) => {
        if (Array.isArray(article.calendar)) {
            article.calendar.forEach((event) => {
                const startDate = moment(event.startDate);
                const endDate = moment(event.endDate);
                while (startDate.isSameOrBefore(endDate)) {
                    dates.push(startDate.format('YYYY-MM-DD'));
                    startDate.add(1, 'day');
                }
            });
        }
    });
    return dates;
};

const CalendarPage = ({ fetchCalendar, calendarContent, loading }) => {
    const [date, setDate] = useState();
    const [selectedDateEvents, setSelectedDateEvents] = useState([]);
    const [highlightedDates, setHighlightedDates] = useState([]);
    const [clickedDate, setClickedDate] = useState(moment());
    const [clickedContent, setClickedContent] = useState(null);

    const { trackPageView } = useMatomo();
    // Track page view
    useEffect(() => {
        trackPageView();
    }, []);

    addLocale('de', calendarLocale);

    const contentWithEvents =
        selectedDateEvents.length > 0 ? selectedDateEvents : calendarContent;

    const height = 5;

    const eventTransitions = useTransition(
        contentWithEvents
            .flatMap((article) =>
                article.calendar.map((event, i) => ({
                    ...event,
                    articleId: article.id,
                    articleTitle: article.title,
                    y: i * height,
                }))
            )
            .sort((a, b) => {
                const startDateA = new Date(a.startDate);
                const startDateB = new Date(b.startDate);
                return startDateA - startDateB;
            }),
        {
            from: { opacity: 0 },
            leave: { height: 0, opacity: 0, config: { duration: 20 } },
            enter: ({ y }) => ({ y, opacity: 1, delay: 200 }),
            update: ({ y }) => ({ y }),
            key: (item) => item?.id,
        }
    );

    useEffect(() => {
        // Get the start and end dates of the selected month
        const startOfMonth = moment(date).startOf('month').format('YYYY-MM-DD');

        // Fetch calendar with the start and end dates
        fetchCalendar(startOfMonth);
    }, [date, fetchCalendar]);

    useEffect(() => {
        setHighlightedDates(generateHighlightedDates(calendarContent));
    }, [calendarContent]);

    const dateTemplate = (event) => {
        if (event.day && event.month !== undefined) {
            // Adjust the month to be one-based
            const formattedDate = moment(
                `${event.year}-${event.month + 1}-${event.day}`,
                'YYYY-M-D'
            ).format('YYYY-MM-DD');

            const isHighlighted = highlightedDates.includes(formattedDate);
            const isClicked = clickedDate === formattedDate;

            return (
                <div
                    key={`${event.year}-${event.month + 1}-${event.day}`}
                    className={`custom-date ${
                        isHighlighted ? 'highlighted' : ''
                    } ${isClicked ? 'clicked' : ''}`}
                    onClick={() => setClickedDate(formattedDate)}
                >
                    {event.day}
                </div>
            );
        }
        return null;
    };

    const handleOnSelect = useCallback(
        (e) => {
            const selectedDate = moment(e.value);

            // If a date is selected, fetch calendar for that date
            if (selectedDate.isValid()) {
                setClickedDate(selectedDate.format('YYYY-MM-DD'));

                const eventsOnSelectedDate = calendarContent
                    .map((article) => {
                        const matchingEvents = article.calendar
                            .map((event, i) => {
                                const eventStartDate = moment(event.startDate);
                                const eventEndDate = moment(event.endDate);

                                // Check if the selected date is within the range of the event
                                if (
                                    selectedDate.isSame(
                                        eventStartDate,
                                        'day'
                                    ) ||
                                    selectedDate.isSameOrBefore(
                                        eventEndDate,
                                        'day'
                                    ) ||
                                    eventStartDate.isAfter(selectedDate, 'day')
                                ) {
                                    return {
                                        id: event.id,
                                        startDate: event.startDate,
                                        endDate: event.endDate,
                                        startTime: event.startTime,
                                        endTime: event.endTime,
                                        articleTitle: article.title,
                                        articleId: article.id,
                                        y: i * height,
                                    };
                                }

                                return null; // Return null for events that don't match the criteria
                            })
                            .filter(Boolean); // Remove null values from the events

                        // Only include the article if it has matching events
                        return matchingEvents.length > 0
                            ? { ...article, calendar: matchingEvents }
                            : null;
                    })
                    .filter(Boolean); // Remove null values from the articles

                // Now eventsOnSelectedDate contains only articles with matching events

                // Update the state with the selected date and corresponding events
                setSelectedDateEvents(eventsOnSelectedDate);
            } else {
                // If no date is selected, fetch calendar for the whole month
                const startOfMonth = moment(date)
                    .startOf('month')
                    .format('YYYY-MM-DD');
                fetchCalendar(startOfMonth);
                setSelectedDateEvents([]); // Clear selected date events
            }
        },
        [date, fetchCalendar, calendarContent]
    );

    const onClickEvent = useCallback(
        (eventId) => {
            // Find the corresponding event from calendarContent based on eventId
            const clickedEvent = calendarContent.find(
                (event) => event.id === eventId
            );

            // Update the state with the clicked event for the side area
            setClickedContent(clickedEvent);
        },
        [calendarContent]
    );

    const handleOnMonthChange = useCallback(
        (e) => {
            // Handle month change logic here
            // Fetch calendar content based on the new month
            const newDate = new Date(e.year, e.month - 1, 1); // Adjust month to zero-based index
            const startOfMonth = moment(newDate)
                .startOf('month')
                .format('YYYY-MM-DD');

            // Fetch calendar content based on the new month
            fetchCalendar(startOfMonth);
        },
        [fetchCalendar]
    );

    return (
        <>
            <Metadata
                title="Calendar"
                description="Calendar page with events "
            />
            <div className=" padding-top-30">
                <div className="container">
                    <div className="row">
                        {!isMobileOnly ? (
                            <HorizontalIframeHomepage />
                        ) : (
                            <HalfBanner />
                        )}
                        <div className="col-lg-6 col-sm-6 col-sm-12 mb30 padding20 white_bg border-radious5 center">
                            <Calendar
                                locale="de"
                                panelClassName="panelCalendar"
                                value={date}
                                onChange={(e) => setDate(e.value)}
                                inline
                                dateTemplate={dateTemplate}
                                onMonthChange={handleOnMonthChange}
                                onSelect={handleOnSelect}
                            />
                        </div>

                        <div className="col-lg-6 col-sm-6 col-sm-12 ">
                            <div
                                className="padding20 white_bg mb30 border-radious5"
                                style={{
                                    minHeight: '490px',
                                    maxHeight: '490px',
                                    overflowY: 'auto',
                                }}
                            >
                                {eventTransitions((props, item) => (
                                    <animated.div
                                        style={props}
                                        key={item.id}
                                        className="cursor_pointer"
                                    >
                                        <div
                                            onClick={() =>
                                                onClickEvent(item.articleId)
                                            }
                                            className="single_post post_type12 type20"
                                        >
                                            <div className="single_post_text calendar_title">
                                                <p className="calendar_date_info">
                                                    {moment(
                                                        item.startDate
                                                    ).format('LL')}
                                                    {' - '}
                                                    {moment(
                                                        item.endDate
                                                    ).format('LL')}
                                                    {item.startTime && (
                                                        <>
                                                            {', '}
                                                            {moment(
                                                                item.startTime,
                                                                'HH:mm:ss'
                                                            ).format('LT')}
                                                            {' Uhr'}
                                                        </>
                                                    )}
                                                </p>
                                                <div className="alignright">
                                                    <add-to-calendar-button
                                                        name={item.articleTitle}
                                                        options="'Apple','Google','Outlook.com'"
                                                        location=""
                                                        startDate={
                                                            item.startDate
                                                        }
                                                        endDate={item.endDate}
                                                        startTime={
                                                            item.startTime &&
                                                            item.endTime
                                                                ? moment(
                                                                      item.startTime,
                                                                      'HH:mm:ss'
                                                                  ).format(
                                                                      'HH:mm'
                                                                  )
                                                                : null
                                                        }
                                                        endTime={
                                                            item.endTime &&
                                                            item.startTime
                                                                ? moment(
                                                                      item.endTime,
                                                                      'HH:mm:ss'
                                                                  ).format(
                                                                      'HH:mm'
                                                                  )
                                                                : null
                                                        }
                                                        timeZone="Europe/Berlin"
                                                        buttonStyle="round"
                                                        listStyle="dropdown"
                                                        size="3"
                                                        hideIconList
                                                        hideIconModal
                                                        hideTextLabelButton
                                                        hideBackground
                                                        hideCheckmark
                                                        hideBranding
                                                        forceOverlay
                                                    />
                                                </div>
                                                <h3>{item.articleTitle}</h3>
                                            </div>
                                        </div>
                                    </animated.div>
                                ))}
                                {contentWithEvents.length === 0 && (
                                    <p>No events for the selected date.</p>
                                )}
                                {loading && (
                                    <Spinner
                                        className="spinner_custom"
                                        animation="border"
                                        variant="success"
                                    />
                                )}
                            </div>
                        </div>
                        <div className="col-12 clicked-content-wrapper">
                            <div className="mb30 padding20 white_bg border-radious5 clicked-section">
                                {/* bottom content */}
                                {clickedContent && (
                                    <Link
                                        key={clickedContent.id}
                                        to={
                                            (clickedContent.type === 'offer'
                                                ? `/offer/`
                                                : `/article/`) +
                                            clickedContent.slug
                                        }
                                    >
                                        <div className="single_post post_type12 type20">
                                            <div className="post_img">
                                                <div className="img_wrap">
                                                    {/* <Link
                                    to={
                                        (clickedContent.type === 'offer'
                                            ? `/offer/`
                                            : `/article/`) + clickedContent.slug
                                    }
                                > */}
                                                    {clickedContent &&
                                                    clickedContent.main_image ? (
                                                        <img
                                                            className="lazyLoad border-radious5"
                                                            src={
                                                                clickedContent.main_image &&
                                                                clickedContent
                                                                    .main_image
                                                                    .formats &&
                                                                clickedContent
                                                                    .main_image
                                                                    .formats
                                                                    .small
                                                                    ? `${
                                                                          process
                                                                              .env
                                                                              .REACT_APP_CMS_URL_IMAGE +
                                                                          clickedContent
                                                                              .main_image
                                                                              .formats
                                                                              .small
                                                                              .url
                                                                      }`
                                                                    : `${
                                                                          process
                                                                              .env
                                                                              .REACT_APP_CMS_URL_IMAGE +
                                                                          clickedContent
                                                                              .main_image
                                                                              .url
                                                                      }`
                                                            }
                                                            alt="thumb"
                                                            effect="blur"
                                                        />
                                                    ) : (
                                                        <img
                                                            className="lazyLoad border-radious5"
                                                            src="{
                                                                    rgOfferPlaceholderSmall
                                                                }"
                                                            alt="thumb"
                                                            effect="blur"
                                                        />
                                                    )}
                                                    {/* </Link> */}
                                                </div>
                                            </div>
                                            {clickedContent && (
                                                <div className="single_post_text">
                                                    <h3>
                                                        {/* <Link
                                        to={
                                            (clickedContent.type === 'offer'
                                                ? `/offer/`
                                                : `/article/`) + clickedContent.slug
                                        }
                                    > */}
                                                        {clickedContent.title}
                                                        {/* </Link> */}
                                                    </h3>
                                                    <div className="row">
                                                        <div className="col-12 align-self-center">
                                                            <div className="meta_col">
                                                                <p>
                                                                    {moment(
                                                                        clickedContent.publishAt
                                                                    ).format(
                                                                        'LL'
                                                                    )}

                                                                    {clickedContent.type ===
                                                                        'offer' &&
                                                                    clickedContent.company
                                                                        ? `, ${clickedContent.company}`
                                                                        : ''}

                                                                    {clickedContent.type ===
                                                                        'offer' &&
                                                                    clickedContent.region
                                                                        ? `, ${clickedContent.region}`
                                                                        : ''}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p className="post-p">
                                                        {
                                                            clickedContent.description
                                                        }
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </Link>
                                )}
                                {!clickedContent && (
                                    <p>
                                        Click event on the list to explore more
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        calendarContent: state.calendar.content,
        loading: state.calendar.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCalendar: (startOfMonth, endOfMonth) =>
            dispatch(fetchCalendar(startOfMonth, endOfMonth)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarPage);
