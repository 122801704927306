import React from 'react';

const FontAwesome = ({ name = '', size, ...rest }) => {
    let sizeClass = '';
    if (size === 'lg') {
        sizeClass = 'fa-lg';
    } else if (size === '2x') {
        sizeClass = 'fa-2x';
    }
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <i id="myIcon" {...rest} className={`fa fa-${name} ${sizeClass}`} />;
};

export default FontAwesome;
